export const WEEK_DAYS_SHORT = ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'];

export function getCalendarDaysWithFullWeeks(date: Date) {
  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear();
  const month = formattedDate.getMonth();

  // Pierwszy i ostatni dzień miesiąca
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  // Pobieranie dnia tygodnia (0 to niedziela, 1 to poniedziałek, itd.)
  const firstDayOfMonthWeekday = firstDayOfMonth.getDay();
  const lastDayOfMonthWeekday = lastDayOfMonth.getDay();

  // Obliczamy pierwszy poniedziałek przed początkiem miesiąca (jeśli pierwszy dzień miesiąca nie jest poniedziałkiem)
  const firstVisibleDay = new Date(firstDayOfMonth);
  firstVisibleDay.setDate(
    firstVisibleDay.getDate() -
      (firstDayOfMonthWeekday === 0 ? 6 : firstDayOfMonthWeekday - 1),
  );

  // Obliczamy ostatnią niedzielę po końcu miesiąca (jeśli ostatni dzień miesiąca nie jest niedzielą)
  const lastVisibleDay = new Date(lastDayOfMonth);
  lastVisibleDay.setDate(
    lastVisibleDay.getDate() +
      (lastDayOfMonthWeekday === 0 ? 0 : 7 - lastDayOfMonthWeekday),
  );

  const calendarDays = [];

  // Dodajemy dni od pierwszego poniedziałku do ostatniej niedzieli
  for (
    let d = firstVisibleDay;
    d <= lastVisibleDay;
    d.setDate(d.getDate() + 1)
  ) {
    calendarDays.push(new Date(d));
  }

  return calendarDays;
}

export const MONTHS = [
  {
    name: 'Styczeń',
    value: 0,
  },
  {
    name: 'Luty',
    value: 1,
  },
  {
    name: 'Marzec',
    value: 2,
  },
  {
    name: 'Kwiecień',
    value: 3,
  },
  {
    name: 'Maj',
    value: 4,
  },
  {
    name: 'Czerwiec',
    value: 5,
  },
  {
    name: 'Lipiec',
    value: 6,
  },
  {
    name: 'Sierpień',
    value: 7,
  },
  {
    name: 'Wrzesień',
    value: 8,
  },
  {
    name: 'Październik',
    value: 9,
  },
  {
    name: 'Listopad',
    value: 10,
  },
  {
    name: 'Grudzień',
    value: 11,
  },
];

export const YEARS = new Array(7)
  .fill(0)
  .map((_, index) => new Date().getFullYear() - 5 + index);

export function getDaysOfDateWeek(date: Date) {
  // Znajdź poniedziałek tygodnia (0 - niedziela, 1 - poniedziałek, ..., 6 - sobota)
  const dayOfWeek = date.getDay();
  const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Jeśli jest niedziela (0), ustaw różnicę do 6

  const monday = new Date(date);
  monday.setDate(date.getDate() - diffToMonday); // Przesuń na poniedziałek

  // Utwórz listę dni tygodnia
  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(monday);
    day.setDate(monday.getDate() + i);
    daysOfWeek.push(day);
  }

  return daysOfWeek;
}

export function createClassNameByIndex(index: number) {
  return `day-${(index % 7) + 1}nth`;
}

export function isCurrentMonth(date: Date, currentDate: Date) {
  return date.getMonth() === new Date(currentDate).getMonth();
}

export function isCurrentDate(date: Date, currentDate: Date) {
  return date.toDateString() === new Date(currentDate).toDateString();
}

export function getWeekNumber(date: Date) {
  // Skopiuj datę, żeby nie modyfikować oryginału
  const currentDate = new Date(date.getTime());

  // Ustaw pierwszy dzień tygodnia na poniedziałek (opcjonalne, w zależności od regionu)
  const dayOfWeek = (currentDate.getDay() + 6) % 7; // Przesuń niedzielę na koniec

  // Ustaw na początek tygodnia
  currentDate.setDate(currentDate.getDate() - dayOfWeek + 3);

  // Ustaw datę na pierwszy dzień roku
  const firstJan = new Date(currentDate.getFullYear(), 0, 1);

  // Oblicz różnicę dni między aktualną datą a pierwszym dniem roku
  const dayOfYear = Math.floor(
    (currentDate.getTime() - firstJan.getTime()) / (24 * 60 * 60 * 1000),
  );

  // Oblicz numer tygodnia
  const weekNumber = Math.ceil(dayOfYear / 7);

  return weekNumber;
}

export function formatDateToYYYYMMDD(date: Date) {
  const year: number = date.getFullYear();
  const month: string = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są indeksowane od 0
  const day: string = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function formatDateToHi(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
}

export function getChangedYearDate(year: number, date: string) {
  const yearDate = new Date(date).setFullYear(year);
  const dayDate = new Date(yearDate).setDate(1);

  return new Date(dayDate);
}

export function getChangedMonthDate(month: number, date: string) {
  const monthDate = new Date(date).setMonth(month);
  const dayDate = new Date(monthDate).setDate(1);

  return new Date(dayDate);
}
